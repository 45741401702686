import { useState } from "react";
import SideBarNavigation from "./sideBarNavigation";
import { Outlet } from "react-router-dom";
import useDeviceDetect from "../../Hooks/useDeviceDetect";
import Card from "../Common Components/Card/card";
import { Modal, message, Avatar, Badge, Dropdown, Popover } from "antd";
import { IoIosArrowDown } from "react-icons/io";

export default function Layout() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const items = [
    {
      key: "0",
      label: "Reset Password",
      //onClick: handleResetPassword,
    },
    {
      key: "1",
      label: "Logout",
      //onClick: signOut,
    },
  ];
  const isMobileDevice = useDeviceDetect();
  const details = JSON.parse(localStorage?.getItem("user"));
  const buildHeaderLayout = () => {
    const username = details?.first_name;
    const userAvatarInitials = username?.charAt(0)?.toUpperCase();

    return (
      <Card>
        <header className="my-4 pl-spacing3 pr-spacing3 max-h-[4.5rem] flex justify-between bg-primaryBgMain">
          <div className="font-semibold">
            <div className="flex h-16">
              {/* <img
                src={details?.company_logo}
                alt="company logo"
                className="pr-1"
              />
              <span className="uppercase" style={{ fontSize: "xx-large" }}>
                {details ? details?.short_name : null}
              </span> */}
            </div>
          </div>
          <div className="flex items-center justify-end px-6">
            {!isMobileDevice && (
              <>
                <div className="mx-2">
                  <Avatar
                    shape="circle"
                    className="cursor-pointer bg-[#B2CFD9]"
                    size="small"
                    alt={username}
                  >
                    {userAvatarInitials}
                  </Avatar>
                </div>
                <Dropdown
                  trigger={"click"}
                  menu={{
                    items,
                  }}
                >
                  <div className="flex">
                    {/* {contextHolder} */}
                    <span className="user_name">{username}</span>
                    <span>
                      <IoIosArrowDown color="grey" className="cursor-pointer" />
                    </span>
                  </div>
                </Dropdown>
              </>
            )}
          </div>
        </header>
      </Card>
    );
  };
  return (
    <div className="flex">
      <SideBarNavigation
        isMobile={mobileOpen}
        handleDrawer={handleDrawerToggle}
      />
      <main className="flex flex-col grow w-[calc(100%-16rem)]">
        {buildHeaderLayout()}
        <div className="max-w-full h-full max-h-screen-lg pl-spacing3 pr-spacing3 pb-8 scrollbar-hide overflow-y-auto bg-primaryBgMain">
          <Outlet />
        </div>
      </main>
    </div>
  );
}
