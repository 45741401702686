import { Routes, Route } from "react-router-dom";
import { Spin } from "antd";
import routes, {
  Login,
  PublicRoute,
  Dashboard,
  Group,
  Contact,
  Compose,
  CreateEmailPage,
  Schedule,
  Status,
  ViewTemplates,
  ConfirmRemplates,
} from "./Route";
import Layout from "./Components/Layout";
import { Suspense } from "react";

const Router = () => {
  return (
    <>
      <Routes>
        <Route element={<Layout />}>
          <Route
            exact
            path={routes.DASHBORD}
            element={
              <Suspense>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            exact
            path={routes.GROUP}
            element={
              <Suspense>
                <Group />
              </Suspense>
            }
          />
          <Route
            exact
            path={`${routes.CONTACT}/:groupId`}
            element={
              <Suspense>
                <Contact />
              </Suspense>
            }
          />
          <Route
            exact
            path={routes.COMPOSE}
            element={
              <Suspense>
                <Compose />
              </Suspense>
            }
          />

          <Route
            exact
            path={routes.EMAIL}
            element={
              <Suspense>
                <CreateEmailPage />
              </Suspense>
            }
          />
          <Route
            exact
            path={routes.SCHEDULE}
            element={
              <Suspense>
                <Schedule />
              </Suspense>
            }
          />
          <Route
            exact
            path={routes.STATUS}
            element={
              <Suspense>
                <Status />
              </Suspense>
            }
          />
          <Route
            exact
            path={`${routes.VIEW_TEMPLATES}/:transcationId`}
            element={
              <Suspense>
                <ViewTemplates />
              </Suspense>
            }
          />
          <Route
            exact
            path={`${routes.CONFIRM_TEMPLATES}/:transcationId/:templateId`}
            element={
              <Suspense>
                <ConfirmRemplates />
              </Suspense>
            }
          />
        </Route>

        <Route element={<PublicRoute />}>
          <Route exact path={routes.LOGIN} element={<Login />} />
        </Route>
      </Routes>
    </>
  );
};
export default Router;
