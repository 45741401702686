// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customCard.useDefaultTheme {
  border-radius: 0;
  box-shadow: none;
}
.customCard.useDefaultTheme .ant-card-body {
  background-color: #f7f7f7;
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common%20Components/Card/card.scss","webpack://./src/Components/Common Components/Card/card.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,gBAAA;ACAJ;ADCI;EACE,yBAAA;EACA,UAAA;EACA,gBAAA;EACA,gBAAA;ACCN","sourcesContent":[".customCard {\n  &.useDefaultTheme {\n    border-radius: 0;\n    box-shadow: none;\n    .ant-card-body {\n      background-color: #f7f7f7;\n      padding: 0;\n      border-radius: 0;\n      box-shadow: none;\n    }\n  }\n}\n",".customCard.useDefaultTheme {\n  border-radius: 0;\n  box-shadow: none;\n}\n.customCard.useDefaultTheme .ant-card-body {\n  background-color: #f7f7f7;\n  padding: 0;\n  border-radius: 0;\n  box-shadow: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
