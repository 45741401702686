// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customButton {
  font-family: "Poppins", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common%20Components/Button/Button.scss","webpack://./src/Components/Common Components/Button/Button.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;ACCF","sourcesContent":[".customButton{\n  font-family: \"Poppins\", sans-serif;\n}",".customButton {\n  font-family: \"Poppins\", sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
