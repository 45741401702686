import React from "react";
import { Card as AntCard } from "antd";
import "../Card/card.scss";

const Card = ({ children, bordered = false, useDefaultTheme = true }) => {
  return (
    <AntCard
      bordered={bordered}
      className={`${useDefaultTheme ? "useDefaultTheme" : ""} customCard`}
    >
      {children}
    </AntCard>
  );
};

export default Card;
