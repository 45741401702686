import Config from "../Services/config";
import axios from "axios";

const userLogin = (data) => {
  return axios.post(`${Config.API_URL}/api/v1/accounts/login/`, data);
};
const authService = {
  userLogin,
};
export default authService;
