import "../../Styles/SideNavigation.scss";
import { useNavigate } from "react-router-dom";
import { menus } from "../../ServicesHooks/authHooks";
import { Menu } from "antd";
import toggle from "../../Assets/toggle.png";
import Button from "../../Components/Common Components/Button/Button";
import { useToggle } from "react-use";
import useDeviceDetect from "../../Hooks/useDeviceDetect";
import Gen_logo from "../../Assets/GenAImail-logo.svg";
const getItem = ({ menuName, menuKey, menuIcon, subMenu, menuType }) => ({
  key: menuKey,
  type: menuType,
  icon: menuIcon,
  label: menuName,
  children: subMenu ? subMenu?.map(getItem) : null,
});
const SideBarNavigation = () => {
  const navigate = useNavigate();

  const [collapsed, toggleCollapsed] = useToggle(false);
  const isMobileDevice = useDeviceDetect();
  const currentMenuItems = menus;

  const items = currentMenuItems.map(getItem);
  return (
    <>
      {!isMobileDevice && (
        <div className="flex relative main-sidebar">
          <div
            className={` ${collapsed ? "w-64 " : "w-16 p-3 "} 
            bg-secondaryBgMain h-screen relative transition-all duration-300 hidden sm:block overflow-y-auto scrollbar-hide`}
          >
            <div className="flex flex-col items-center justify-center">
              <Button
                type={"text"}
                className={`flex items-center justify-center space-x-3 ${
                  collapsed ? "mt-8" : ""
                } m-4`}
                // onClick={() => navigate(firstPermittedTab)}
              >
                <img src={Gen_logo} alt="yvi-logo" className="yvi-logo w-20" />
                {/* {collapsed && (
                  <span className="text-[#848484] text-xl font-bold">
                    GENIAI
                  </span>
                )} */}
              </Button>
              <Menu
                items={items}
                mode="inline"
                defaultSelectedKeys={["1"]}
                inlineCollapsed={!collapsed}
                onClick={({ key }) => {
                  let link;

                  const chosenMenu = currentMenuItems.find(
                    (item) => item.menuName === key
                  );
                  link = chosenMenu.menuLink;

                  navigate(link);
                }}
              />
            </div>
          </div>
          <div className={"duration-300 bg-white"}>
            <img
              src={toggle}
              alt="sidenav-toggle"
              onClick={() => toggleCollapsed()}
              className={`absolute cursor-pointer top-9 -right-3 w-6 ${
                !collapsed && "rotate-180"
              } z-10`}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default SideBarNavigation;
