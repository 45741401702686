import { RiBallPenLine } from "react-icons/ri";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { AiOutlineSetting } from "react-icons/ai";
import { GoPeople } from "react-icons/go";
import { CiHeadphones } from "react-icons/ci";
import routes from "../Route";
const DEFAULT_ERROR_MESSAGE =
  "Something went wrong, Please try again after sometime";

const FULL_MENU_LIST = {
  dashboard: {
    menuKey: "Dashboard",
    menuName: "Dashboard",
    menuIcon: <MdOutlineSpaceDashboard />,
    menuLink: routes.DASHBORD,
  },
  Contacts: {
    menuKey: "Contacts",
    menuName: "Contacts",
    menuIcon: <AiOutlineSetting />,
    menuLink: routes.GROUP,
  },
  Compose: {
    menuKey: "Compose",
    menuName: "Compose",
    menuIcon: <CiHeadphones />,
    menuLink: routes.COMPOSE,
  },
  Schedule: {
    menuKey: "Schedule",
    menuName: "Schedule",
    menuIcon: <RiBallPenLine />,
    menuLink: routes.SCHEDULE,
  },
  Status: {
    menuKey: "Status",
    menuName: "Status",
    menuIcon: <GoPeople />,
    menuLink: routes.STATUS,
  },
};

export { FULL_MENU_LIST, DEFAULT_ERROR_MESSAGE };
