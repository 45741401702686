import { lazy } from "react";
import { Outlet } from "react-router-dom";

const Login = lazy(() => import("./Pages/Auth/Login"));
const Dashboard = lazy(() => import("./Pages/Dashbord/DashboardView.js"));
const Group = lazy(() => import("./Pages/Contacts/GroupList.js"));
const Contact = lazy(() => import("./Pages/Contacts/ContactList.js"));
const Compose = lazy(() => import("./Pages/Compose/index.js"));
const CreateEmailPage = lazy(() => import("./Pages/Compose/Email.js"));
const Schedule = lazy(() => import("./Pages/Schedule/index.js"));
const Status = lazy(() => import("./Pages/Status/index.js"));
const CreateSchedulePage = lazy(() => import("./Pages/Schedule/Schedule.js"));
const ViewTemplates = lazy(() => import("./Pages/Compose/ViewTemplates.js"));
const ConfirmRemplates = lazy(() =>
  import("./Pages/Compose/ConfirmTemplates.js")
);
const routes = {
  LOGIN: "/",
  DASHBORD: "gen/dashboard",
  GROUP: "gen/group",
  CONTACT: "gen/contact",
  COMPOSE: "gen/compose",
  EMAIL: "gen/Email",
  SCHEDULE: "gen/schedule",
  STATUS: "gen/status",
  CREATE_SCHEDULE: "gen/ScheduleDetails",
  VIEW_TEMPLATES: "gen/templates",
  CONFIRM_TEMPLATES: "gen/view-templates",
};

const PublicRoute = () => (
  <div>
    <Outlet />
  </div>
);

export {
  Login,
  PublicRoute,
  Dashboard,
  Group,
  Contact,
  Compose,
  CreateEmailPage,
  Schedule,
  Status,
  CreateSchedulePage,
  ViewTemplates,
  ConfirmRemplates,
};
export default routes;
