// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-sidebar .yvi-logo {
  padding-inline: 0;
  min-width: 1.75rem;
}
.main-sidebar .ant-menu-item {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.main-sidebar .ant-menu-item-icon {
  color: #848484;
  font-size: 1.875rem;
}
.main-sidebar .ant-menu-item:hover {
  background-color: #0a7f8f !important;
}
.main-sidebar .ant-menu-item:hover .ant-menu-title-content {
  color: white;
}
.main-sidebar .ant-menu-item:hover .ant-menu-item-icon {
  color: white;
}
.main-sidebar .ant-menu-item-selected {
  background-color: #0a7f8f !important;
}
.main-sidebar .ant-menu-item-selected .ant-menu-title-content {
  color: white;
}
.main-sidebar .ant-menu-item-selected .ant-menu-item-icon {
  color: white;
}
.main-sidebar > .ant-tooltip-open {
  background-color: #0a7f8f;
}
.main-sidebar .ant-menu-title-content {
  color: #848484;
  font-size: 1rem;
  font-weight: 500;
  padding-left: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/Styles/SideNavigation.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,kBAAA;AAAJ;AAEE;EACE,kBAAA;EACA,qBAAA;AAAJ;AAEE;EACE,cAAA;EACA,mBAAA;AAAJ;AAEE;EACE,oCAAA;AAAJ;AACI;EACE,YAAA;AACN;AACI;EACE,YAAA;AACN;AAEE;EACE,oCAAA;AAAJ;AACI;EACE,YAAA;AACN;AACI;EACE,YAAA;AACN;AAEE;EACE,yBAAA;AAAJ;AAEE;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,oBAAA;AAAJ","sourcesContent":[".main-sidebar {\n  .yvi-logo {\n    padding-inline: 0;\n    min-width: 1.75rem;\n  }\n  .ant-menu-item {\n    margin-top: 0.5rem;\n    margin-bottom: 0.5rem;\n  }\n  .ant-menu-item-icon {\n    color: #848484;\n    font-size: 1.875rem;\n  }\n  .ant-menu-item:hover {\n    background-color: #0a7f8f !important;\n    .ant-menu-title-content {\n      color: white;\n    }\n    .ant-menu-item-icon {\n      color: white;\n    }\n  }\n  .ant-menu-item-selected {\n    background-color: #0a7f8f !important;\n    .ant-menu-title-content {\n      color: white;\n    }\n    .ant-menu-item-icon {\n      color: white;\n    }\n  }\n  & > .ant-tooltip-open {\n    background-color: #0a7f8f;\n  }\n  .ant-menu-title-content {\n    color: #848484;\n    font-size: 1rem;\n    font-weight: 500;\n    padding-left: 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
