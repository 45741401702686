import { useMutation } from "@tanstack/react-query";
import authService from "../Services/authService";
import { FULL_MENU_LIST } from "../Utilities/constants";

const menus = [
  FULL_MENU_LIST.dashboard,
  FULL_MENU_LIST.Contacts,
  FULL_MENU_LIST.Compose,
  FULL_MENU_LIST.Schedule,
  FULL_MENU_LIST.Status,
];

const adminMenus = [
  FULL_MENU_LIST.dashboard,
  FULL_MENU_LIST.jobs,
  FULL_MENU_LIST.candidates,
  FULL_MENU_LIST.interviews,
  FULL_MENU_LIST.questionBank,
  FULL_MENU_LIST.communication,
  FULL_MENU_LIST.settings,
  FULL_MENU_LIST.report,
  FULL_MENU_LIST.registration,
  FULL_MENU_LIST.license,
  FULL_MENU_LIST.enquires,
  FULL_MENU_LIST.questions,
];

const useLogin = (config) => {
  const { onError = () => null, onSuccess = () => null } = config;
  const mutation = useMutation({
    mutationFn: async (body) => {
      const details = await authService.userLogin(body);
      console.log("details", details.data);
      localStorage.setItem("user", JSON.stringify(details.data));
      return details?.data;
    },
    onSuccess,
    onError,
  });
  return mutation;
};
export { useLogin, menus };
